const CryptoJS = require('crypto-js');  //引用AES源码js
    
const key = CryptoJS.enc.Utf8.parse("fedup2020fedup20");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');   //十六位十六进制数作为密钥偏移量
let _KEY = 'fedup2020fedup20';

var CBCOptions = {
    iv: CryptoJS.enc.Utf8.parse('abcdefghijklmnop'),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  };
//解密方法
function decrypt(word) {
    var key = CryptoJS.enc.Utf8.parse(_KEY);
    var decrypt = CryptoJS.AES.decrypt(
        word, 
        key, 
        CBCOptions
    );
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

//加密方法
function encrypt(word) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
}
export {
    decrypt ,
    encrypt,
}